import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCircleNotch,
    faSignInAlt,
    faCaretDown,
    faReceipt,
    faWrench,
    faComments,
    faTrashAlt,
    faEnvelope,
    faShareAlt,
    faStar,
    faChevronDown,
    faTimes,
    faCheck,
    faShoppingCart,
    faSearch,
    faSignOutAlt,
    faCaretRight,
    faAngleRight,
    faAngleLeft,
    faPlus,
    faMinus,
    faCheckCircle,
    faExclamation,
    faCropAlt,
    faArchive,
    faEllipsisV,
    faTimesCircle,
    faPlayCircle,
    faHeadphones,
    faDownload,
    faUserAlt,
    faUserCircle,
    faWindowMaximize
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebook,
    faTwitter,
    faPinterest,
    faInstagram,
    faSafari,
    faFirefox,
    faInternetExplorer,
    faOpera
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faArchive,
    faCircleNotch,
    faEllipsisV,
    faTimes,
    faSignInAlt,
    faChevronDown,
    faCaretDown,
    faReceipt,
    faWrench,
    faComments,
    faTrashAlt,
    faEnvelope,
    faShareAlt,
    faStar,
    faCheck,
    faShoppingCart,
    faSignOutAlt,
    faSearch,
    faCaretRight,
    faAngleRight,
    faAngleLeft,
    faFacebook,
    faTwitter,
    faPinterest,
    faInstagram,
    faPlus,
    faMinus,
    faExclamation,
    faCheckCircle,
    faCropAlt,
    faSafari,
    faFirefox,
    faInternetExplorer,
    faOpera,
    faTimesCircle,
    faPlayCircle,
    faHeadphones,
    faDownload,
    faUserAlt,
    faUserCircle,
    faWindowMaximize
);
