import React, { Suspense, lazy, Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';

import { Header } from '../layout';

import { Spinner } from '../components/Spinner';
import './icons';
import './yup';

import './app.scss';

const loadPage = page => props => {
    const Component = lazy(() => import(`../pages/${page}`));
    return <Component {...props} />;
};

class App extends Component {
    render() {
        return (
            <Fragment>
                <Helmet
                    titleTemplate="%s - BTR Report System"
                    defaultTitle="BTR Report System"
                />
                <Header />
                <main role="main">
                    <Suspense fallback={<Spinner />}>
                        <Switch>
                            <Route path="/call-report" component={loadPage('report/Report')} />
                            <Route
                                path="/change-password"
                                exact
                                component={loadPage('change-password/ChangePassword')}
                            />
                            <Route
                                path="/:reportName"
                                component={loadPage('report/CommonReport')}
                            />
                            <Route component={loadPage('login/Login')} />
                        </Switch>
                    </Suspense>
                </main>
            </Fragment>
        );
    }
}

export default App;
