import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './profile.module.scss';

import { Card } from '../../components/Card';

const HeaderProfileMenu = ({ onSignout }) => (
    <div className={styles.profileMenu}>
        <Card>
            <div className={styles.navigation}>
                <ul>
                    <li>
                        <NavLink to="/change-password" activeClassName={styles.active}>
                            Change password
                        </NavLink>
                    </li>
                    <li className={styles.profileMenuItem}>
                        <button type="button" onClick={onSignout}>
                            Sign out
                        </button>
                    </li>
                </ul>
            </div>
        </Card>
    </div>
);

export default HeaderProfileMenu;
