import { HttpError } from '../../core';

export const fetchAccount = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/user/data`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
    });

    const jsonData = await response.json();
    if (!response.ok) {
        throw new HttpError(jsonData.error, response.status);
    }

    return jsonData;
};

// export const updateAccount = async accountData => {
//     let fd = new FormData();
//     Object.keys(accountData).forEach(k => {
//         fd.append(k, accountData[k] || '');
//     });

//     const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/accounts/me/`, {
//         method: 'PUT',
//         headers: {
//    Authorization: `Bearer ${sessionStorage.getItem('token')}`
//         },
//         body: fd
//     });

//     const jsonData = await response.json();
//     if (!response.ok) {
//         throw new HttpError(jsonData.error, response.status);
//     }

//     return jsonData.data;
// };

// export const signoutAccount = async () => {
//     const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/accounts/signout/`, {
//         method: 'DELETE',
//         headers: {
//             Authorization: `Token ${sessionStorage.getItem('token')}`,
//             'Content-Type': 'application/json'
//         }
//     });

//     const jsonData = await response.json();
//     if (!response.ok) {
//         throw new HttpError(jsonData.error, response.status);
//     }

//     return jsonData.data;
// };
