import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useAuth } from '../../core/auth';
import { Row, Container } from '../../components/Grid';

import HeaderProfile from './HeaderProfile';
import HeaderMenu from './HeaderMenu';
import styles from './header.module.scss';

const Header = () => {
    const auth = useAuth();
    const [scrolled, setScrolled] = useState(false);

    const isSignedIn = () => {
        return auth.token !== null;
    };

    useEffect(() => {
        onScroll();
        document.addEventListener('scroll', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, []);

    const onScroll = () => {
        const scrollTop =
            (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop ||
            document.scrollingElement;

        if (scrollTop >= 120) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    return (
        <header
            className={classnames({
                [styles.header]: true,
                [styles.headerScrolled]: scrolled
            })}
        >
            <Container>
                <Row>
                    <div className={`${styles.headerCol} ${styles.logoCol}`}>
                        <div className={styles.logoImg}>
                            <Link to="/call-report">
                                <img
                                    src={require('../../assets/img/logo.png')}
                                    alt="Report system"
                                />
                            </Link>
                        </div>
                    </div>
                    {isSignedIn() && <HeaderMenu />}
                    <div className={styles.headerCol}>
                        <HeaderProfile />
                    </div>
                </Row>
            </Container>
        </header>
    );
};

export default Header;
