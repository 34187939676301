import * as yup from 'yup';

yup.setLocale({
    mixed: {
        required: 'This field is required.'
    },
    string: {
        email: 'Enter a valid email address.',
        // eslint-disable-next-line no-template-curly-in-string
        min: 'Ensure this field has at least ${min} characters.'
    }
});

function equalTo(ref, msg) {
    return yup.mixed().test({
        name: 'equalTo',
        exclusive: false,
        message: msg || 'Passwords do not match.',
        params: {
            reference: ref.path
        },
        test: function(value) {
            return value === this.resolve(ref);
        }
    });
}
yup.addMethod(yup.string, 'equalTo', equalTo);
