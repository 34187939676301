import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './menu.module.scss';
import rscStyle from './header-container.module.scss';
import headerStyles from './header.module.scss';
import { Col } from '../../components/Grid';
import RSC from 'react-scrollbars-custom';

export default class HeaderMenu extends Component {
    render() {
        return (
            <Col className={headerStyles.headerCol}>
                <RSC className={rscStyle.rsc}>
                    <ul className={styles.menuLinks}>
                        <li className={styles.hideMobile}>
                            <NavLink
                                className={styles.link}
                                activeClassName={styles.active}
                                to="/call-report"
                            >
                                Call report
                            </NavLink>
                        </li>
                        <li className={styles.hideMobile}>
                            <NavLink
                                className={styles.link}
                                activeClassName={styles.active}
                                to="/queue-summary-report"
                            >
                                Queue summary report
                            </NavLink>
                        </li>
                        <li className={styles.hideMobile}>
                            <NavLink
                                className={styles.link}
                                activeClassName={styles.active}
                                to="/summary-report"
                            >
                                Summary report
                            </NavLink>
                        </li>
                        {/* <li className={styles.hideMobile}>
                            <NavLink
                                className={styles.link}
                                activeClassName={styles.active}
                                to="/ivr-report"
                            >
                                Ivr Report General
                            </NavLink>
                        </li> */}
                        {/* <li className={styles.hideMobile}>
                            <NavLink
                                className={styles.link}
                                activeClassName={styles.active}
                                to="/agents-queue-report"
                            >
                                Agents queue report
                            </NavLink>
                        </li> */}
                        {/* <li className={styles.hideMobile}>
                            <NavLink
                                className={styles.link}
                                activeClassName={styles.active}
                                to="/out-bound-agent-report"
                            >
                                Outbound Agent report
                            </NavLink>
                        </li> */}
                        {/* <li className={styles.hideMobile}>
                            <NavLink
                                className={styles.link}
                                activeClassName={styles.active}
                                to="/ivr-evaluation-report"
                            >
                                IVR Evaluation report
                            </NavLink>
                        </li> */}
                        {/* <li className={styles.hideMobile}>
                            <NavLink
                                className={styles.link}
                                activeClassName={styles.active}
                                to="/agent-break-detailed-report"
                            >
                                Aux report
                            </NavLink>
                        </li> */}
                        <li className={styles.hideMobile}>
                            <NavLink
                                className={styles.link}
                                activeClassName={styles.active}
                                to="/login-agent-report"
                            >
                                Login Agent report
                            </NavLink>
                        </li>
                        {/* <li className={styles.hideMobile}>
                            <NavLink
                                className={styles.link}
                                activeClassName={styles.active}
                                to="/break-fine-report"
                            >
                                Break Fine report
                            </NavLink>
                        </li> */}
                    </ul>
                </RSC>
            </Col>
        );
    }
}
