import React from 'react';
import classnames from 'classnames';
import { shape, string, oneOf } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './profile.module.scss';

const Profile = ({ account, size }) => {
    return (
        <div
            className={classnames({
                [styles.profile]: true,
                [styles.large]: size === 'lg',
                [styles.small]: size === 'sm'
            })}
        >
            <FontAwesomeIcon size="2x" icon="user-circle" />
            <span className={styles.userName}>{account.fullname}</span>
            <FontAwesomeIcon size="1x" icon="chevron-down" />
        </div>
    );
};

Profile.propTypes = {
    account: shape({
        company: string.isRequired,
        fullname: string.isRequired
    }).isRequired,
    size: oneOf(['lg', 'sm'])
};

Profile.defaultProps = {
    size: 'lg'
};

export default Profile;
