import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './profile.module.scss';

import HeaderProfileMenu from './HeaderProfileMenu';

import { useAuth } from '../../core/auth';

import { Fetcher } from '../../components/Fetcher';

import { fetchAccount } from './api';
import { Profile } from '../../components/Profile';

const dropdownStates = {
    appear: styles.enter,
    appearActive: styles.enterActive,
    enter: styles.enter,
    enterDone: styles.enterDone,
    enterActive: styles.enterActive,
    exit: styles.exit,
    exitDone: styles.exitDone,
    exitActive: styles.exitActive
};

const HeaderProfile = () => {
    const auth = useAuth();
    const [dropdownVisible, setShowDropdown] = useState(false);
    const isSignedIn = () => {
        return auth.token !== null;
    };

    const signout = async e => {
        e.preventDefault();

        try {
            auth.clearToken();
        } catch (e) {}
    };

    const clickOutsideDropdown = e => {
        setShowDropdown(false);
        document.removeEventListener('click', clickOutsideDropdown);
    };

    const showDropdown = e => {
        e.preventDefault();
        setShowDropdown(true);
        document.addEventListener('click', clickOutsideDropdown);
    };

    useEffect(() => {
        return () => {
            document.removeEventListener('click', clickOutsideDropdown);
        };
    }, []);

    function renderContents() {
        if (isSignedIn()) {
            return (
                <div
                    className={styles.profileBox}
                    onMouseOver={showDropdown}
                    onMouseLeave={clickOutsideDropdown}
                >
                    <Fetcher action={fetchAccount}>
                        {data => <Profile account={data} size="lg" />}
                    </Fetcher>
                    <CSSTransition
                        classNames={dropdownStates}
                        timeout={500}
                        in={dropdownVisible}
                        appear={true}
                        unmountOnExit
                    >
                        <HeaderProfileMenu onSignout={signout} />
                    </CSSTransition>
                </div>
            );
        }
    }

    return <div className={styles.headerProfile}>{renderContents()}</div>;
};

export default HeaderProfile;
