import React, { Children, Component } from 'react';

import AuthContext from './AuthContext';

export default class AuthProvider extends Component {
    state = { token: null, ready: false };

    setToken = async (token, requires_activation) => {
        await sessionStorage.setItem('token', token);
        this.setState({ token });
    };

    clearToken = async () => {
        await sessionStorage.removeItem('token');
        this.setState({ token: null });
    };

    async componentDidMount() {
        const token = await sessionStorage.getItem('token');
        if (token) {
            this.setState({ token });
        }

        this.setState({ ready: true });
    }

    render() {
        if (!this.state.ready) {
            return null;
        }

        return (
            <AuthContext.Provider
                value={{
                    token: this.state.token,
                    setToken: this.setToken,
                    clearToken: this.clearToken
                }}
            >
                {Children.only(this.props.children)}
            </AuthContext.Provider>
        );
    }
}
